input {
  &:focus,
  &:not(:placeholder-shown) {
    + label {
      background-color: #fff;
      font-size: calculate-rem(10);
      padding: calculate-rem(2) calculate-rem(5) 0;
      top: -#{calculate-rem(8)};
    }
  }
  &:checked {
    + svg {
      background-color: map_get($greys, 'grey-900');
      border-color: map_get($greys, 'grey-900');
      color: #fff;
    }
  }
}
