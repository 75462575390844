.ProductCard {
  $root: &;
  
  &Media, &Details {
    transition: transform .4s ease-in-out;
  }
  
  &:hover {
    #{$root} {
      &Media {
        transform: translateY(calculate-rem(-8px))
      }
      
      &Details {
        transform: translateY(calculate-rem(-4px))
      }
      &Title {
        color: map-get($colors, 'azure');
      }
    }
  }
}

.StripeCard {
  $root: &;
  &:nth-of-type(even) {
    margin-right: 0;
  }
  @include breakpoint-respond-up(desktop) {
    &:nth-of-type(2) {
      margin-right: calculate-rem(32px);
    }
  }
}
