.HeaderElement {
  height: calc(80vh - 50px);
  max-height: 842px;
  min-height: 700px;
  @include breakpoint-respond-up(tablet) {
    height: calc(80vh - 130px);
  }
  
  &Overlay {
    height: 80vh;
    margin-top: -50px;
    @include breakpoint-respond-up(tablet) {
      margin-top: -130px;
    }
    
  }
}
