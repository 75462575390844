.transform-50\/-50 {
  transform: translate(-50%,-50%);
}

.HeroOverlay {
  margin-top: -50px;
  @include breakpoint-respond-up(tablet) {
    margin-top: -130px;
  }
  
}

.paragraphs {
  & > p {
    margin-bottom: calculate-rem(32)
  }
}

.place-description {
  h3 {
    font-size: calculate-rem(24px);
    margin-bottom: calculate-rem(16px);
  }
}

