.bg-gradient-to {
  &-top {
    background-image: linear-gradient(to top, var(--lp-gradient-stops));
  }
  &-right {
    background-image: linear-gradient(to right, var(--lp-gradient-stops));
  }
  &-bottom {
    background-image: linear-gradient(to bottom, var(--lp-gradient-stops));
  }
  &-left {
    background-image: linear-gradient(to left, var(--lp-gradient-stops));
  }
  &-bottom-right {
    background-image: linear-gradient(to bottom right, var(--lp-gradient-stops));
  }
  &-bottom-left {
    background-image: linear-gradient(to bottom left, var(--lp-gradient-stops));
  }
}


@each $label, $color in map-merge($hue, $greys) {
  .from-#{$label} {
    --lp-gradient-from: #{$color};
    --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
  }
  .to-#{$label} {
    --lp-gradient-to: #{$color};
  }
}
