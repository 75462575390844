.loading-enter-active, .loading-leave-active {
  transition: opacity 2s cubic-bezier(1, 0.2, 0.8, 1);
}
.loading-enter, .loading-leave-to {
  opacity: 0;
}

.fx-matrix {
  transform: perspective(1600px) rotateY(var(--lp-effect-direction));
  transition: transform .75s ease 0s;
  
  &:hover {
    transform: perspective(3000px) rotateY(5deg);
  }
}

.fx-left {
  --lp-effect-direction: 20deg;
}

.fx-right {
  --lp-effect-direction: -20deg;
}

.animate {
  animation: none;
  
  &-spin {
    animation: spin 1s linear infinite;
  }
  
  &-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }
  
  &-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  
  &-bounce {
    animation: bounce 1s infinite;
  }
}




// Keyframes

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animationTimingFunction: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animationTimingFunction: cubic-bezier(0, 0, 0.2, 1);
  }
}
