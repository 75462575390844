@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Montserrat:400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,500&display=swap");
@keyframes lazy-animation {
  from {
    opacity: 0;
    visibility: hidden;
    filter: blur(30px);
  }
  to {
    opacity: 1;
    visibility: visible;
    filter: blur(0);
  }
}
:root {
  --aspect-ratio: 0;
}

body {
  overflow-x: hidden;
  overflow-y: scroll;
}

.FauxButton {
  font-size: 0.6875rem;
  background-color: transparent;
  border-radius: 0;
  border: 1px solid #fff;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 8px 14px;
  text-transform: uppercase;
  transition: all 0.5s;
}
.FauxButton__icon {
  font-size: 1rem;
  display: inline-block;
  fill: currentColor;
  height: 1em;
  line-height: 1;
  margin-bottom: 2px;
  vertical-align: middle;
  width: 1em;
}
.FauxButton--next .FauxButton__icon {
  margin-left: 4px;
}
.FauxButton--previous .FauxButton__icon {
  margin-right: 4px;
}

.CalloutLink {
  display: inline-block;
}
.CalloutLinkLine {
  background-color: #e4e4e4;
  height: 1px;
  width: calc(100% + 64px);
}
.CalloutLink a {
  font-size: 0.6875rem;
  color: #fff;
  display: block;
  font-weight: 600;
  letter-spacing: 0.015em;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.2s ease-in-out 0s;
}
.CalloutLink a:hover > svg {
  transform: translateX(-3px);
}
.CalloutLink svg {
  display: inline-block;
  fill: currentColor;
  height: 5px;
  line-height: 1;
  margin-left: 8px;
  margin-top: -2px;
  transition: transform 0.2s ease-in-out;
  vertical-align: middle;
  width: 5px;
}

.Button {
  font-size: 0.6875rem;
  -webkit-appearance: none;
  backface-visibility: hidden;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  letter-spacing: 0.03125rem;
  line-height: 1;
  overflow: hidden;
  padding: 0.875rem 1.5625rem;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  transition: background-color 0.3s, color 0.3s;
}

.ProductCardMedia, .ProductCardDetails {
  transition: transform 0.4s ease-in-out;
}
.ProductCard:hover .ProductCardMedia {
  transform: translateY(-0.5rem);
}
.ProductCard:hover .ProductCardDetails {
  transform: translateY(-0.25rem);
}
.ProductCard:hover .ProductCardTitle {
  color: rgb(71, 154, 217);
}

.StripeCard:nth-of-type(even) {
  margin-right: 0;
}
@media (min-width: 992px) {
  .StripeCard:nth-of-type(2) {
    margin-right: 2rem;
  }
}

.ResponsiveContainer {
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  box-sizing: border-box;
  position: relative;
  max-width: 1280px;
}
@media (min-width: 768px) {
  .ResponsiveContainer {
    margin-left: 2rem;
    margin-right: 2rem;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 992px) {
  .ResponsiveContainer {
    margin-left: 4rem;
    margin-right: 4rem;
  }
}
@media (min-width: 1416px) {
  .ResponsiveContainer {
    margin-left: auto;
    margin-right: auto;
  }
}

.loading-enter-active, .loading-leave-active {
  transition: opacity 2s cubic-bezier(1, 0.2, 0.8, 1);
}

.loading-enter, .loading-leave-to {
  opacity: 0;
}

.fx-matrix {
  transform: perspective(1600px) rotateY(var(--lp-effect-direction));
  transition: transform 0.75s ease 0s;
}
.fx-matrix:hover {
  transform: perspective(3000px) rotateY(5deg);
}

.fx-left {
  --lp-effect-direction: 20deg;
}

.fx-right {
  --lp-effect-direction: -20deg;
}

.animate {
  animation: none;
}
.animate-spin {
  animation: spin 1s linear infinite;
}
.animate-ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}
.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.animate-bounce {
  animation: bounce 1s infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}
@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animationTimingFunction: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animationTimingFunction: cubic-bezier(0, 0, 0.2, 1);
  }
}
.playfair {
  font-family: "Playfair Display", serif;
}

@font-face {
  font-family: "Benton Sans";
  font-style: normal;
  font-stretch: normal;
  font-weight: 300;
  src: url(/fonts/2c8c3478-e1ba-4af3-bfd0-9fea259fc17f-2.eot);
  src: url(/fonts/2c8c3478-e1ba-4af3-bfd0-9fea259fc17f-2.eot?) format("embedded-opentype"), url(/fonts/2c8c3478-e1ba-4af3-bfd0-9fea259fc17f-3.woff) format("woff"), url(/fonts/2c8c3478-e1ba-4af3-bfd0-9fea259fc17f-1.ttf) format("truetype");
}
@font-face {
  font-family: "Benton Sans";
  font-style: normal;
  font-stretch: normal;
  font-weight: 500;
  src: url(/fonts/19319132-31a6-45e2-85a5-6dacae897490-2.eot);
  src: url(/fonts/19319132-31a6-45e2-85a5-6dacae897490-2.eot?) format("embedded-opentype"), url(/fonts/19319132-31a6-45e2-85a5-6dacae897490-3.woff) format("woff"), url(/fonts/19319132-31a6-45e2-85a5-6dacae897490-1.ttf) format("truetype");
}
@font-face {
  font-family: "Benton Sans";
  font-style: normal;
  font-stretch: normal;
  font-weight: 600;
  src: url(/fonts/743d3d3a-da58-48d2-a5c3-bd7994650e23-2.eot);
  src: url(/fonts/743d3d3a-da58-48d2-a5c3-bd7994650e23-2.eot?) format("embedded-opentype"), url(/fonts/743d3d3a-da58-48d2-a5c3-bd7994650e23-3.woff) format("woff"), url(/fonts/743d3d3a-da58-48d2-a5c3-bd7994650e23-1.ttf) format("truetype");
}
@font-face {
  font-family: "Miller Daily";
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  src: url(/fonts/6e603ae4-800b-4625-9fa8-1819315263a6-2.eot);
  src: url(/fonts/6e603ae4-800b-4625-9fa8-1819315263a6-2.eot?) format("embedded-opentype"), url(/fonts/6e603ae4-800b-4625-9fa8-1819315263a6-3.woff) format("woff"), url(/fonts/6e603ae4-800b-4625-9fa8-1819315263a6-1.ttf) format("truetype");
}
@font-face {
  font-family: "Miller Daily";
  font-style: italic;
  font-stretch: normal;
  font-weight: normal;
  src: url(/fonts/2d4b6287-e087-4bce-89af-61a0cd634f27-2.eot);
  src: url(/fonts/2d4b6287-e087-4bce-89af-61a0cd634f27-2.eot?) format("embedded-opentype"), url(/fonts/2d4b6287-e087-4bce-89af-61a0cd634f27-3.woff) format("woff"), url(/fonts/2d4b6287-e087-4bce-89af-61a0cd634f27-1.ttf) format("truetype");
}
input:focus + label, input:not(:placeholder-shown) + label {
  background-color: #fff;
  font-size: 0.625rem;
  padding: 0.125rem 0.3125rem 0;
  top: -0.5rem;
}
input:checked + svg {
  background-color: #1f1f1f;
  border-color: #1f1f1f;
  color: #fff;
}

.bg-gradient-to-top {
  background-image: linear-gradient(to top, var(--lp-gradient-stops));
}
.bg-gradient-to-right {
  background-image: linear-gradient(to right, var(--lp-gradient-stops));
}
.bg-gradient-to-bottom {
  background-image: linear-gradient(to bottom, var(--lp-gradient-stops));
}
.bg-gradient-to-left {
  background-image: linear-gradient(to left, var(--lp-gradient-stops));
}
.bg-gradient-to-bottom-right {
  background-image: linear-gradient(to bottom right, var(--lp-gradient-stops));
}
.bg-gradient-to-bottom-left {
  background-image: linear-gradient(to bottom left, var(--lp-gradient-stops));
}

.from-blue {
  --lp-gradient-from: rgb(41, 124, 187);
  --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
}

.to-blue {
  --lp-gradient-to: rgb(41, 124, 187);
}

.from-cyan {
  --lp-gradient-from: rgb(39, 170, 225);
  --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
}

.to-cyan {
  --lp-gradient-to: rgb(39, 170, 225);
}

.from-azure {
  --lp-gradient-from: rgb(71, 154, 217);
  --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
}

.to-azure {
  --lp-gradient-to: rgb(71, 154, 217);
}

.from-light-blue {
  --lp-gradient-from: rgb(138, 190, 229);
  --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
}

.to-light-blue {
  --lp-gradient-to: rgb(138, 190, 229);
}

.from-logo-blue {
  --lp-gradient-from: rgb(0, 87, 217);
  --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
}

.to-logo-blue {
  --lp-gradient-to: rgb(0, 87, 217);
}

.from-red {
  --lp-gradient-from: rgb(255, 77, 79);
  --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
}

.to-red {
  --lp-gradient-to: rgb(255, 77, 79);
}

.from-green {
  --lp-gradient-from: rgb(22, 201, 141);
  --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
}

.to-green {
  --lp-gradient-to: rgb(22, 201, 141);
}

.from-dark-green {
  --lp-gradient-from: rgb(71, 168, 153);
  --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
}

.to-dark-green {
  --lp-gradient-to: rgb(71, 168, 153);
}

.from-light-green {
  --lp-gradient-from: rgb(199, 230, 170);
  --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
}

.to-light-green {
  --lp-gradient-to: rgb(199, 230, 170);
}

.from-yellow {
  --lp-gradient-from: rgb(254, 239, 109);
  --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
}

.to-yellow {
  --lp-gradient-to: rgb(254, 239, 109);
}

.from-orange {
  --lp-gradient-from: rgb(255, 200, 63);
  --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
}

.to-orange {
  --lp-gradient-to: rgb(255, 200, 63);
}

.from-beige {
  --lp-gradient-from: rgb(203, 203, 175);
  --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
}

.to-beige {
  --lp-gradient-to: rgb(203, 203, 175);
}

.from-purple {
  --lp-gradient-from: rgb(146, 84, 222);
  --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
}

.to-purple {
  --lp-gradient-to: rgb(146, 84, 222);
}

.from-mauve {
  --lp-gradient-from: rgb(104, 78, 121);
  --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
}

.to-mauve {
  --lp-gradient-to: rgb(104, 78, 121);
}

.from-pink {
  --lp-gradient-from: rgb(255, 112, 142);
  --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
}

.to-pink {
  --lp-gradient-to: rgb(255, 112, 142);
}

.from-cerise {
  --lp-gradient-from: rgb(236, 10, 140);
  --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
}

.to-cerise {
  --lp-gradient-to: rgb(236, 10, 140);
}

.from-gold {
  --lp-gradient-from: rgb(255, 215, 0);
  --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
}

.to-gold {
  --lp-gradient-to: rgb(255, 215, 0);
}

.from-silver {
  --lp-gradient-from: rgb(196, 202, 206);
  --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
}

.to-silver {
  --lp-gradient-to: rgb(196, 202, 206);
}

.from-bronze {
  --lp-gradient-from: rgb(80, 50, 20);
  --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
}

.to-bronze {
  --lp-gradient-to: rgb(80, 50, 20);
}

.from-grey-100 {
  --lp-gradient-from: #f6f6f6;
  --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
}

.to-grey-100 {
  --lp-gradient-to: #f6f6f6;
}

.from-grey-200 {
  --lp-gradient-from: #e2e7ea;
  --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
}

.to-grey-200 {
  --lp-gradient-to: #e2e7ea;
}

.from-grey-300 {
  --lp-gradient-from: #e4e4e4;
  --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
}

.to-grey-300 {
  --lp-gradient-to: #e4e4e4;
}

.from-grey-400 {
  --lp-gradient-from: #c0ccd2;
  --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
}

.to-grey-400 {
  --lp-gradient-to: #c0ccd2;
}

.from-grey-500 {
  --lp-gradient-from: #adb5bd;
  --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
}

.to-grey-500 {
  --lp-gradient-to: #adb5bd;
}

.from-grey-600 {
  --lp-gradient-from: #8f959e;
  --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
}

.to-grey-600 {
  --lp-gradient-to: #8f959e;
}

.from-grey-700 {
  --lp-gradient-from: #6b727b;
  --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
}

.to-grey-700 {
  --lp-gradient-to: #6b727b;
}

.from-grey-800 {
  --lp-gradient-from: #2c3543;
  --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
}

.to-grey-800 {
  --lp-gradient-to: #2c3543;
}

.from-grey-900 {
  --lp-gradient-from: #1f1f1f;
  --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
}

.to-grey-900 {
  --lp-gradient-to: #1f1f1f;
}

.from-grey-1000 {
  --lp-gradient-from: #1b1c1d;
  --lp-gradient-stops: var(--lp-gradient-from), var(--lp-gradient-to, rgba(darken($color, 10), 0));
}

.to-grey-1000 {
  --lp-gradient-to: #1b1c1d;
}

.ReIcon {
  background-size: auto;
  display: inline-block;
  fill: currentColor;
  height: 100%;
  line-height: 1;
  vertical-align: middle;
  width: 100%;
}

.SocialIcon {
  height: 2.5rem;
  transition-duration: 0.2s;
  transition-property: color, background-color;
  width: 2.5rem;
}
.SocialIcon svg {
  height: 1.5rem;
  width: 1.5rem;
}

.IconBadge {
  height: 2.5rem;
  max-height: 2.5rem;
  max-width: 2.5rem;
  min-height: 2.5rem;
  min-width: 2.5rem;
  width: 2.5rem;
}

.HeaderElement {
  height: calc(80vh - 50px);
  max-height: 842px;
  min-height: 700px;
}
@media (min-width: 768px) {
  .HeaderElement {
    height: calc(80vh - 130px);
  }
}
.HeaderElementOverlay {
  height: 80vh;
  margin-top: -50px;
}
@media (min-width: 768px) {
  .HeaderElementOverlay {
    margin-top: -130px;
  }
}

.ImageFitted {
  object-fit: cover;
}
.ImageShadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}
.ImageProductShadow {
  box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.2), 0 10px 14px 1px rgba(0, 0, 0, 0.14), 0 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.ImageRounded {
  border-radius: 4px;
}
.ImageFullCover {
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

img:not([src]):not([srcset]) {
  visibility: hidden;
}

@-moz-document url-prefix() {
  img:-moz-loading {
    visibility: hidden;
  }
}
.aspect-ratio-square {
  --aspect-ratio-w: 1;
  --aspect-ratio-h: 1;
  padding-bottom: calc(var(--aspect-ratio-h) / var(--aspect-ratio-w) * 100%);
  position: relative;
}

.aspect-ratio-4x3 {
  --aspect-ratio-w: 4;
  --aspect-ratio-h: 3;
  padding-bottom: calc(var(--aspect-ratio-h) / var(--aspect-ratio-w) * 100%);
  position: relative;
}

.aspect-ratio-16x9 {
  --aspect-ratio-w: 16;
  --aspect-ratio-h: 9;
  padding-bottom: calc(var(--aspect-ratio-h) / var(--aspect-ratio-w) * 100%);
  position: relative;
}

.aspect-ratio-21x9 {
  --aspect-ratio-w: 21;
  --aspect-ratio-h: 9;
  padding-bottom: calc(var(--aspect-ratio-h) / var(--aspect-ratio-w) * 100%);
  position: relative;
}

.aspect-ratio-3x4 {
  --aspect-ratio-w: 3;
  --aspect-ratio-h: 4;
  padding-bottom: calc(var(--aspect-ratio-h) / var(--aspect-ratio-w) * 100%);
  position: relative;
}

.aspect-ratio-9x16 {
  --aspect-ratio-w: 9;
  --aspect-ratio-h: 16;
  padding-bottom: calc(var(--aspect-ratio-h) / var(--aspect-ratio-w) * 100%);
  position: relative;
}

.aspect-ratio-9x21 {
  --aspect-ratio-w: 9;
  --aspect-ratio-h: 21;
  padding-bottom: calc(var(--aspect-ratio-h) / var(--aspect-ratio-w) * 100%);
  position: relative;
}

[class*=aspect-ratio] {
  background-color: red;
}
[class*=aspect-ratio] > * {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.SectionHero {
  width: 61.25rem;
}
@media (max-width: 991px) {
  .SectionHero {
    max-width: 42.5rem;
    width: 87.5%;
  }
}

a {
  color: rgb(71, 154, 217);
  transition-duration: 0.3s;
  transition-property: color;
  text-decoration: inherit;
}

.SectionHeadline {
  font-size: 4.25rem;
  color: #1f1f1f;
  font-weight: 600;
  letter-spacing: -0.035em;
  line-height: 1;
  text-align: center;
}
@media (max-width: 991px) {
  .SectionHeadline {
    font-size: 3.75rem;
  }
}

.SectionHeading {
  font-size: 3.5rem;
  color: #1f1f1f;
  font-weight: 600;
  letter-spacing: -0.025em;
  line-height: 1.2;
}

.SectionHeadtext {
  font-size: 1.75rem;
  color: #1f1f1f;
  font-weight: 300;
  letter-spacing: -0.025em;
  line-height: 1.3;
}

.SectionCopy {
  font-size: 1.25rem;
  margin-left: auto;
  margin-right: auto;
  color: #6b727b;
  font-weight: 500;
  letter-spacing: -0.015em;
  line-height: 1.4;
  margin-top: 1rem;
  text-align: center;
  width: 65%;
}
@media (max-width: 991px) {
  .SectionCopy {
    font-size: 1.1875rem;
    width: 100%;
  }
}

.HeroHeading {
  font-size: 2.875rem;
  color: #1f1f1f;
  font-weight: 600;
  line-height: 1.25;
}

.Heading {
  font-size: 2.25rem;
  color: #1f1f1f;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: -0.015em;
}

.Title {
  font-size: 1.625rem;
  color: #1f1f1f;
  font-weight: 600;
  letter-spacing: -0.015em;
  line-height: 1.25;
}

.CardTitle {
  font-size: 1.25rem;
  color: #1f1f1f;
  font-weight: 600;
  letter-spacing: -0.015em;
  line-height: 1.25;
}

.CategoryLabel {
  font-size: 0.6875rem;
  color: #1f1f1f;
  font-weight: 600;
  text-transform: uppercase;
}

.Author {
  font-size: 0.6875rem;
  color: #6b727b;
  font-weight: 500;
  text-transform: uppercase;
}

.Copy {
  font-size: 0.9375rem;
  color: #6b727b;
  font-family: "Benton Sans", -apple-system, BlinkMacSystemFont, Segoe UI, Rototo, Helvetica, Arial, sans-serif;
  line-height: 1.5;
}

.Date {
  font-size: 0.6875rem;
  color: #6b727b;
  font-weight: 500;
  text-transform: uppercase;
}

.Advertorial {
  font-size: 0.5rem;
  color: #c0ccd2;
  font-weight: 600;
  text-transform: uppercase;
}

.TextPrimaryLink {
  color: #1f1f1f;
  cursor: pointer;
  transition-duration: 0.3s;
  transition-property: color;
}
.TextPrimaryLink:hover {
  color: rgb(71, 154, 217);
}

.TextSecondaryLink {
  color: #6b727b;
  cursor: pointer;
  transition-duration: 0.3s;
  transition-property: color;
}
.TextSecondaryLink:hover {
  color: rgb(71, 154, 217);
}

.transform-50\/-50 {
  transform: translate(-50%, -50%);
}

.HeroOverlay {
  margin-top: -50px;
}
@media (min-width: 768px) {
  .HeroOverlay {
    margin-top: -130px;
  }
}

.paragraphs > p {
  margin-bottom: 2rem;
}

.place-description h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.ADV:before {
  color: #1f1f1f;
  content: "Pubblicità";
  display: block;
  font-size: 0.5rem;
  left: 0;
  letter-spacing: 0.5px;
  line-height: 1;
  opacity: 0.57;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 1.25rem;
  width: 100%;
}
.ADV:after {
  clear: both;
  content: " ";
  display: block;
}

.ArticleFeatured {
  margin-left: auto;
  margin-right: auto;
  align-items: flex-end;
  background-color: #1f1f1f;
  display: flex;
  max-height: 720px;
  max-width: 1280px;
  overflow: hidden;
  position: relative;
  text-align: left;
  text-decoration: none;
}
.ContentFeatured .ArticleFeatured {
  height: 513px !important;
  min-height: 513px !important;
}
.ArticleFeaturedContent {
  z-index: 3;
}
@media (min-width: 1416px) {
  .ArticleFeaturedContent {
    width: 85%;
  }
}
.ArticleFeaturedCategory {
  color: #fff;
}
.ArticleFeaturedShadow {
  background-image: linear-gradient(to right top, #000, rgba(0, 0, 0, 0) 56%);
  bottom: 0;
  left: 0;
  opacity: 0.67;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.ArticleFeatured.hero {
  max-height: 80vh;
  max-width: none;
}
.ContentFeatured .ArticleFeatured.hero {
  height: 80vh !important;
  min-height: 80vh !important;
}
@media (max-width: 1279px) {
  .ArticleFeatured.hero .ArticleFeaturedContent {
    margin-left: 5%;
    margin-right: 5%;
  }
}
@media (min-width: 992px) {
  .ArticleFeatured.hero .ArticleFeaturedTitle {
    font-size: 2.875rem;
  }
}
@media (max-width: 767px) {
  .ArticleFeatured.hero .CalloutLink {
    display: none;
  }
}
.ArticleListItemAdvertorial img {
  width: 5rem;
}
@media (min-width: 576px) {
  .ArticleListItemThumbnail {
    max-width: 15rem;
  }
}
@media (min-width: 992px) {
  .ArticleListItemThumbnail {
    max-width: 22.5rem;
  }
}
@media (min-width: 992px) {
  .ArticleListSmall .ArticleListItem:last-of-type {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
@media (min-width: 992px) {
  .ArticleListSmall .ArticleListItemTitle {
    font-size: 1rem;
    max-height: calc(20px * 3);
    overflow: hidden;
    padding-right: 1rem;
    position: relative;
  }
  .ArticleListSmall .ArticleListItemTitle::before {
    bottom: 0;
    content: "...";
    position: absolute;
    right: 0;
  }
  .ArticleListSmall .ArticleListItemTitle::after {
    background-color: #fff;
    content: "";
    height: 20px;
    position: absolute;
    right: 0;
    width: 20px;
  }
}
@media (min-width: 992px) {
  .ArticleListSmall .ArticleListItemCopy, .ArticleListSmall .ArticleListItemAuthor {
    display: none;
  }
}
@media (min-width: 992px) {
  .ArticleListSmall .ArticleListItemThumbnail {
    width: 5.625rem;
    height: 5.625rem;
  }
}
@media (min-width: 992px) {
  .ArticleListSmall .ArticleListItemImage {
    height: 5.625rem;
  }
}
.ArticlePaginationNav {
  display: inline-block;
  width: 100%;
}
.ArticlePaginationNav__item {
  align-items: center;
  background-color: #1f1f1f;
  display: flex;
  height: 320px;
  justify-content: center;
  overflow: hidden;
  padding: 40px 50px;
  position: relative;
  text-align: center;
  text-decoration: none;
}
@media (min-width: 768px) {
  .ArticlePaginationNav__item {
    float: left;
    width: 50%;
  }
}
.ArticlePaginationNav__item:first-of-type {
  display: none;
}
@media (min-width: 768px) {
  .ArticlePaginationNav__item:first-of-type {
    display: flex;
  }
}
@media (min-width: 992px) {
  .ArticlePaginationNav__item {
    height: 420px;
  }
}
.ArticlePaginationNav__item img {
  height: 100%;
  left: 0;
  object-fit: cover;
  opacity: 0.6;
  position: absolute;
  top: 0;
  transition: transform 0.8s ease-in-out;
  width: 100%;
}
.ArticlePaginationNav__item:hover img {
  transform: scale(1.05);
}
.ArticlePaginationNav__item__content {
  max-width: 100%;
  position: relative;
}
.ArticlePaginationNav__item__category {
  color: #fff;
  margin-bottom: 16px;
}
.ArticlePaginationNav__item__title {
  color: #fff;
  margin-bottom: 24px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .ArticleCard:last-of-type {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .ArticleCard:last-of-type {
    display: none;
  }
}
@media (min-width: 992px) {
  .ArticleCard {
    max-width: 31.25rem;
  }
}
.ArticleCard + .ArticleCard {
  margin-top: 2.5rem;
}
@media (min-width: 576px) {
  .ArticleCard + .ArticleCard {
    margin-top: 0;
    margin-left: 2.5rem;
  }
}
@media (max-width: 575px) {
  .ArticleCardPicture {
    width: 8.75rem;
  }
}
@media (max-width: 575px) {
  .ArticleCardImage {
    height: 6.25rem;
  }
}
.ArticleCardAdvertorialImage {
  width: 80px;
}

.FooterMainItem {
  border-top: 1px solid #e2e7ea;
  flex-grow: 1;
  flex-basis: 33.333%;
  margin-bottom: 24px;
  min-width: 184px;
  padding-top: 16px;
}
@media (min-width: 768px) {
  .FooterMainItem {
    padding-top: 0;
    border-width: 0;
    flex-basis: 20%;
    min-width: 136px;
  }
}
@media (min-width: 992px) {
  .FooterMainItem {
    flex-basis: 16.666%;
  }
}
.FooterHr {
  border-top: 1px solid #e2e7ea;
}
.FooterBottom {
  grid-column-gap: 1rem;
  grid-row-gap: 0.75rem;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, auto);
}
@media (min-width: 768px) {
  .FooterBottom {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(3, auto);
  }
}
.FooterLogo {
  fill: currentColor;
  grid-area: 1/1/2/2;
  height: 48px;
  text-decoration: none;
  width: 80px;
}
@media (min-width: 768px) {
  .FooterLogo {
    grid-area: 1/1/3/2;
  }
}
.FooterSocials {
  grid-area: 1/3/2/5;
}
@media (min-width: 768px) {
  .FooterSocials {
    grid-area: 1/10/3/13;
  }
}
.FooterSocialsList {
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, auto);
}
@media (min-width: 576px) {
  .FooterSocialsList {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(1, auto);
  }
}
@media (min-width: 768px) {
  .FooterSocialsList {
    grid-row-gap: 0.5rem;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, auto);
  }
}
@media (min-width: 992px) {
  .FooterSocialsList {
    grid-column-gap: 0.5rem;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, auto);
  }
}
.FooterCopyright {
  grid-area: 3/1/4/5;
}
@media (min-width: 768px) {
  .FooterCopyright {
    grid-area: 1/2/3/10;
  }
}

.PaginationItem {
  min-width: 38px;
  height: 38px;
  line-height: 37px;
}
.PaginationItem a {
  background-color: transparent;
  border-color: transparent;
  transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.leaflet-control {
  display: none !important;
}
.leaflet-popup {
  margin-bottom: 40px !important;
}
.leaflet-popup-content {
  margin: 0 !important;
  width: auto !important;
}
.leaflet-popup-content-wrapper {
  padding: 0 !important;
  border-radius: 2px !important;
}
.leaflet-popup-close-button {
  color: white !important;
  font-weight: normal !important;
}
.leaflet-marker-icon {
  outline: none;
}

.hooper-navigation {
  display: flex;
  position: relative;
  justify-content: center;
}
.hooper-prev, .hooper-next {
  position: relative !important;
  top: 0 !important;
  transform: initial !important;
}

.lazy__loaded {
  animation: lazy-animation 0.5s linear;
  transition: opacity 1s;
}

img[lazy=loaded] {
  animation: lazy-animation 0.5s linear;
}

img[lazy=loading] {
  opacity: 0;
}

.vueperslides--full-height {
  height: 100%;
}
.vueperslides--full-height .vueperslides__inner {
  height: 100%;
}
.vueperslides--full-height .vueperslides__parallax-wrapper {
  height: 100%;
}
.vueperslides__arrows {
  display: none;
}
.vueperslides__bullets {
  bottom: 24px !important;
}
@media (max-width: 991px) {
  .vueperslides__bullets {
    display: none !important;
  }
}

.slick-disabled {
  display: none;
}

.vm--container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.vm--overlay {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
.vm--modal {
  top: 0 !important;
  left: 0 !important;
  overflow: scroll !important;
  padding-top: 3rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  padding-bottom: 3rem;
  width: 90% !important;
}
@media (min-width: 768px) {
  .vm--modal {
    padding: 3rem;
  }
}
.vm--modal-tour {
  padding: 0 !important;
}
@media (min-width: 992px) {
  .vm--modal-tour {
    width: 75% !important;
  }
}
@media (min-width: 1280px) {
  .vm--modal-tour {
    width: 60% !important;
  }
}
.vm--modal-tour-visible {
  overflow: visible !important;
}
.vm .v--modal-overlay {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
.vm .v--modal-box {
  overflow: visible !important;
}
.vm .vjs-big-play-centered .vjs-big-play-button {
  margin-left: -1.56666em !important;
  margin-top: -1.56666em !important;
}
.vm .video-js * {
  outline: none;
}
.vm .video-js .vjs-big-play-button {
  background-color: #fff !important;
  border-color: transparent !important;
  border-radius: 100% !important;
  height: 64px !important;
  width: 64px !important;
  top: 33% !important;
  left: 39% !important;
}
@media (min-width: 768px) {
  .vm .video-js .vjs-big-play-button {
    bottom: 32px !important;
    left: 32px !important;
    top: auto !important;
  }
}
.vm .video-js .vjs-big-play-button:focus, .vm .video-js .vjs-big-play-button:hover {
  background-color: rgba(255, 255, 255, 0.75) !important;
}
.vm .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  font-size: 2.625rem;
  color: #1f1f1f;
  position: static !important;
}