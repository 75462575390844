.Article {
  
  &Featured {
    $root: &;
  
    margin-left: auto;
    margin-right: auto;
  
    align-items: flex-end;
    background-color: $default-image-background-color;
    display: flex;
    max-height: 720px;
    max-width: 1280px;
    overflow: hidden;
    position: relative;
    text-align: left;
    text-decoration: none;
    
    .ContentFeatured & {
      height: 513px !important;
      min-height: 513px !important;
    }
    
    &Content {
      z-index: 3;
      @include breakpoint-respond-up(wide) {
        width: 85%;
      }
    }
    
    &Category {
      color: #fff;
    }
    
    &Shadow {
      background-image: linear-gradient(to right top, #000, rgba(0, 0, 0, 0) 56%);
      bottom: 0;
      left: 0;
      opacity: 0.67;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }
    
    &.hero {
      max-height: 80vh;
      max-width: none;
      
      .ContentFeatured & {
        height: 80vh !important;
        min-height: 80vh !important;
      }
      
      #{$root} {
        &Content {
          @include breakpoint-respond-down(large) {
            margin-left: 5%;
            margin-right: 5%;
          }
        }
        
        &Title {
          @include breakpoint-respond-up(desktop) {
            @include fs(46);
          }
        }
      }
      
      .CalloutLink {
        @include breakpoint-respond-down(tablet) {
          display: none;
        }
      }
    }
  }
  
  &List {
    $root: &;
    
    &Item {
      &Advertorial {
        img {
          width: calculate-rem(80px);
        }
      }
      
      &Thumbnail {
        @include breakpoint-respond-up(phone) {
          //height: calculate-rem(170px);
          max-width: calculate-rem(240px);
        }
        @include breakpoint-respond-up(desktop) {
          //height: calculate-rem(240px);
          max-width: calculate-rem(360px);
        }
        img {
          //@include breakpoint-respond-up(phone) {
          //  max-height: calculate-rem(170px);
          //}
          //@include breakpoint-respond-up(desktop) {
          //  max-height: calculate-rem(240px);
          //}
        }
      }
    }
    
    &Small {
      #{$root} {
        &Item {
          &:last-of-type {
            @include breakpoint-respond-up(desktop) {
              border-bottom: 0;
              margin-bottom: 0;
              padding-bottom: 0;
            }
          }
          
          &Title {
            @include breakpoint-respond-up(desktop) {
              @include fs(16);
              @include truncate(20px, 3);
            }
          }
          
          &Copy,
          &Author {
            @include breakpoint-respond-up(desktop) {
              display: none;
            }
          }
          
          &Thumbnail {
            @include breakpoint-respond-up(desktop) {
              width: calculate-rem(90px);
              height: calculate-rem(90px);
            }
          }
          
          &Image {
            @include breakpoint-respond-up(desktop) {
              height: calculate-rem(90px);
            }
          }
        }
      }
    }
  }
  
  &PaginationNav {
    display: inline-block;
    width: 100%;
    
    &__item {
      align-items: center;
      background-color: $grey-900;
      display: flex;
      height: 320px;
      justify-content: center;
      overflow: hidden;
      padding: 40px 50px;
      position: relative;
      text-align: center;
      text-decoration: none;
      @include breakpoint-respond-up(tablet) {
        float: left;
        width: 50%;
      }
      
      &:first-of-type {
        display: none;
        @include breakpoint-respond-up(tablet) {
          display: flex;
        }
      }
      
      @include breakpoint-respond-up(desktop) {
        height: 420px;
      }
      
      img {
        height: 100%;
        left: 0;
        object-fit: cover;
        opacity: .6;
        position: absolute;
        top: 0;
        transition: transform .8s ease-in-out;
        width: 100%;
      }
      
      &:hover {
        img {
          transform: scale(1.05);
        }
      }
      
      &__content {
        max-width: 100%;
        position: relative;
      }
      
      &__category {
        //@include text-elevation(1);
        color: #fff;
        margin-bottom: 16px;
      }
      
      &__title {
        //@include text-elevation(1);
        color: #fff;
        margin-bottom: 24px;
      }
    }
  }
  
  &Card {
    
    &:last-of-type {
      @include breakpoint-respond-only(tablet) {
        display: none;
      }
      @include breakpoint-respond-only(phone) {
        display: none;
      }
    }
    
    @include breakpoint-respond-up(desktop) {
      max-width: calculate-rem(500px);
    }
    
    + #{&} {
      margin-top: calculate-rem(40px);
      @include breakpoint-respond-up(phone) {
        margin-top: 0;
        margin-left: calculate-rem(40px);
      }
    }
    
    &Picture {
      @include breakpoint-respond-down(phone) {
        width: calculate-rem(140px);
      }
    }
    
    &Image {
      @include breakpoint-respond-down(phone) {
        height: calculate-rem(100px);
      }
    }
    
    &Advertorial {
      &Image {
        width: 80px;
      }
    }
    
  }
  
}




