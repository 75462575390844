.Pagination {
  &Item {
    $root: &;
    min-width: 38px;
    height: 38px;
    line-height: 37px;
    a {
      background-color: transparent;
      border-color: transparent;
      transition: border-color .3s ease-in-out, color .3s ease-in-out;
    }
  }
}
