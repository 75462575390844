a {
  color: $link-text-color;
  transition-duration: .3s;
  transition-property: color;
  text-decoration: inherit;
}

.SectionHeadline {
  @include fs(68);
  color: $main-text-color;
  font-weight: $font-weight-bold;
  letter-spacing: -.035em;
  line-height: 1;
  text-align: center;
  @include breakpoint-respond-down(desktop) {
    @include fs(60);
  }
}

.SectionHeading {
  @include fs(56);
  color: $main-text-color;
  font-weight: $font-weight-bold;
  letter-spacing: -.025em;
  line-height: 1.2;
}

//.SectionSubHeading {
//  @include fs(20);
//  font-weight: $font-weight-normal;
//  letter-spacing: -.015em;
//  line-height: 1.3;
//  margin-bottom: 40px;
//}

.SectionHeadtext {
  @include fs(28);
  color: $main-text-color;
  font-weight: $font-weight-light;
  letter-spacing: -.025em;
  line-height: 1.3;
}

.SectionCopy {
  @include fs(20);
  margin-left: auto;
  margin-right: auto;
  color: $secondary-text-color;
  font-weight: $font-weight-normal;
  letter-spacing: -.015em;
  line-height: 1.4;
  margin-top: calculate-rem(16px);
  text-align: center;
  width: 65%;
  @include breakpoint-respond-down(desktop) {
    @include fs(19);
    width: 100%;
  }
}

.HeroHeading {
  @include fs(46);
  color: $main-text-color;
  font-weight: $font-weight-bold;
  line-height: 1.25;
}

.Heading {
  @include fs(36);
  color: $main-text-color;
  font-weight: $font-weight-bold;
  line-height: 1.25;
  letter-spacing: -.015em;
}

.Title {
  @include fs(26);
  color: $main-text-color;
  font-weight: $font-weight-bold;
  letter-spacing: -.015em;
  line-height: 1.25;
}

.CardTitle {
  @include fs(20);
  color: $main-text-color;
  font-weight: $font-weight-bold;
  letter-spacing: -.015em;
  line-height: 1.25;
}

.CategoryLabel {
  @include fs(11);
  color: $main-text-color;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}

.Author {
  @include fs(11);
  color: $secondary-text-color;
  font-weight: $font-weight-normal;
  text-transform: uppercase;
}

.Copy {
  @include fs(15);
  color: $secondary-text-color;
  font-family: $font-family-main;
  line-height: 1.50;
}

.Date {
  @include fs(11);
  color: $secondary-text-color;
  font-weight: $font-weight-normal;
  text-transform: uppercase;
}

.Advertorial {
  @include fs(8);
  color: $light-text-color;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}

.TextPrimaryLink {
  color: $main-text-color;
  cursor: pointer;
  transition-duration: .3s;
  transition-property: color;
  
  &:hover {
    color: $link-text-color;
  }
}

.TextSecondaryLink {
  color: $secondary-text-color;
  cursor: pointer;
  transition-duration: .3s;
  transition-property: color;
  
  &:hover {
    color: $link-text-color;
  }
}
