.ReIcon {
  background-size: auto;
  display: inline-block;
  fill: currentColor;
  height: 100%;
  line-height: 1;
  vertical-align: middle;
  width: 100%;
}

.SocialIcon {
  height: calculate-rem(40);
  transition-duration: .2s;
  transition-property: color, background-color;
  width: calculate-rem(40);
  svg {
    height: calculate-rem(24);
    width: calculate-rem(24);
  }
}

.IconBadge {
  height: calculate-rem(40);
  max-height: calculate-rem(40);
  max-width: calculate-rem(40);
  min-height: calculate-rem(40);
  min-width: calculate-rem(40);
  width: calculate-rem(40);

}
