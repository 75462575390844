.ADV {
  &:before {
    color: $grey-900;
    content: "Pubblicità";
    display: block;
    font-size: calculate-rem(8);
    left: 0;
    letter-spacing: .5px;
    line-height: 1;
    opacity: 0.57;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: calculate-rem(20);
    width: 100%;
  }
  &:after {
    clear: both;
    content: " ";
    display: block;
  }
}
