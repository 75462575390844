.FauxButton {
  @include fs(11);
  background-color: transparent;
  border-radius: 0;
  border: 1px solid #fff;
  color: #fff;
  display: inline-block;
  font-weight: $font-weight-bold;
  padding: 8px 14px;
  text-transform: uppercase;
  transition: all .5s;
  
  &__icon {
    @include fs(16);
    display: inline-block;
    fill: currentColor;
    height: 1em;
    line-height: 1;
    margin-bottom: 2px;
    vertical-align: middle;
    width: 1em;
  }
  
  &--next {
    .FauxButton__icon {
      margin-left: 4px;
    }
  }
  
  &--previous {
    .FauxButton__icon {
      margin-right: 4px;
    }
  }
}

.CalloutLink {
  display: inline-block;
  
  &Line {
    background-color: map_get($greys, 'grey-300');
    height: 1px;
    width: calc(100% + 64px);
  }
  
  a {
    @include fs(11);
    color: #fff;
    display: block;
    font-weight: $font-weight-bold;
    letter-spacing: .015em;
    text-decoration: none;
    text-transform: uppercase;
    transition: color .2s ease-in-out 0s;
    
    &:hover {
      > svg {
        transform: translateX(-3px);
      }
    }
  }
  
  svg {
    display: inline-block;
    fill: currentColor;
    height: 5px;
    line-height: 1;
    margin-left: 8px;
    margin-top: -2px;
    transition: transform .2s ease-in-out;
    vertical-align: middle;
    width: 5px;
  }
}

.Button {
  @include fs(11);
  -webkit-appearance: none;
  backface-visibility: hidden;
  font-family: $font-family-tertiary;
  font-weight: $font-weight-bold;
  letter-spacing: calculate-rem(.5px);
  line-height: 1;
  overflow: hidden;
  padding: calculate-rem(14px) calculate-rem(25px);
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  transition: background-color .3s, color .3s;
}
