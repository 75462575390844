.ResponsiveContainer {
  margin-left: auto;
  margin-right: auto;
  padding-left: calculate-rem(16px);
  padding-right: calculate-rem(16px);
  box-sizing: border-box;
  position: relative;
  max-width: 1280px;
  @include breakpoint-respond-up(tablet) {
    margin-left: calculate-rem(32px);
    margin-right: calculate-rem(32px);
    padding-left: 0;
    padding-right: 0;
  }
  @include breakpoint-respond-up(desktop) {
    margin-left: calculate-rem(64px);
    margin-right: calculate-rem(64px);
  }
  @include breakpoint-respond-up(wide) {
    margin-left: auto;
    margin-right: auto;
  }
  
}
