.Footer {
  &Main {
    &Item {
      border-top: 1px solid $border-color;
      flex-grow: 1;
      flex-basis: 33.333%;
      margin-bottom: 24px;
      min-width: 184px;
      padding-top: 16px;
      @include breakpoint-respond-up(tablet) {
        padding-top: 0;
        border-width: 0;
        flex-basis: 20%;
        min-width: 136px;
      }
      @include breakpoint-respond-up(desktop) {
        flex-basis: 16.666%;
      }
    }
  }
  
  &Hr {
    border-top: 1px solid $border-color;
  }
  
  &Bottom {
    grid-column-gap: calculate-rem(16);
    grid-row-gap: calculate-rem(12);
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, auto);
    @include breakpoint-respond-up(tablet) {
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(3, auto);
    }
  }
  
  &Logo {
    fill: currentColor;
    grid-area: 1 / 1 / 2 / 2;
    height: 48px;
    text-decoration: none;
    width: 80px;
    @include breakpoint-respond-up(tablet) {
      grid-area: 1 / 1 / 3 / 2;
    }
  }
  
  &Socials {
    grid-area:1 / 3 / 2 / 5;
    @include breakpoint-respond-up(tablet) {
      grid-area: 1 / 10 / 3 / 13;
    }
    
    &List {
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: repeat(2, auto);
      @include breakpoint-respond-up(phone) {
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(1, auto);
      }
      @include breakpoint-respond-up(tablet) {
        grid-row-gap: calculate-rem(8);
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, auto);
      }
      @include breakpoint-respond-up(desktop) {
        grid-column-gap: calculate-rem(8);
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(2, auto);
      }
    }
  }
  
  &Copyright {
    grid-area: 3 / 1 / 4 / 5;
    @include breakpoint-respond-up(tablet) {
      grid-area: 1 / 2 / 3 / 10;
    }
  }
}
