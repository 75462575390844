.Image {
  &Fitted {
    object-fit: cover;
  }
  
  &Shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  }
  
  &ProductShadow {
    box-shadow: 0 6px 6px -3px rgba(0, 0, 0, .2), 0 10px 14px 1px rgba(0, 0, 0, .14), 0 4px 18px 3px rgba(0, 0, 0, .12);
  }
  
  &Rounded {
    border-radius: 4px;
  }
  
  &FullCover {
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
}

img:not([src]):not([srcset]) {
  visibility: hidden;
}

@-moz-document url-prefix() {
  img:-moz-loading {
    visibility: hidden;
  }
}

