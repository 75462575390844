//.ratio {
//  position: relative;
//
//  &::before {
//    content: "";
//    display: block;
//    padding: {
//      bottom: var(--aspect-ratio);
//      top: var(--aspect-ratio);
//    }
//    position: relative;
//  }
//
//  &-4\/3 {
//    --aspect-ratio: #{percentage((3 / 4) / 2)};
//  }
//
//  &-16\/9 {
//    --aspect-ratio: #{percentage((9 / 16) / 2)};
//  }
//
//  &-21\/9 {
//    --aspect-ratio: #{percentage((9 / 21) / 2)};
//  }
//}

.aspect-ratio-square {
  --aspect-ratio-w: 1;
  --aspect-ratio-h: 1;
  padding-bottom: calc(var(--aspect-ratio-h) / var(--aspect-ratio-w) * 100%);
  position: relative;
}

.aspect-ratio-4x3 {
  --aspect-ratio-w: 4;
  --aspect-ratio-h: 3;
  padding-bottom: calc(var(--aspect-ratio-h) / var(--aspect-ratio-w) * 100%);
  position: relative;
}

.aspect-ratio-16x9 {
  --aspect-ratio-w: 16;
  --aspect-ratio-h: 9;
  padding-bottom: calc(var(--aspect-ratio-h) / var(--aspect-ratio-w) * 100%);
  position: relative;
}

.aspect-ratio-21x9 {
  --aspect-ratio-w: 21;
  --aspect-ratio-h: 9;
  padding-bottom: calc(var(--aspect-ratio-h) / var(--aspect-ratio-w) * 100%);
  position: relative;
}

.aspect-ratio-3x4 {
  --aspect-ratio-w: 3;
  --aspect-ratio-h: 4;
  padding-bottom: calc(var(--aspect-ratio-h) / var(--aspect-ratio-w) * 100%);
  position: relative;
}

.aspect-ratio-9x16 {
  --aspect-ratio-w: 9;
  --aspect-ratio-h: 16;
  padding-bottom: calc(var(--aspect-ratio-h) / var(--aspect-ratio-w) * 100%);
  position: relative;
}

.aspect-ratio-9x21 {
  --aspect-ratio-w: 9;
  --aspect-ratio-h: 21;
  padding-bottom: calc(var(--aspect-ratio-h) / var(--aspect-ratio-w) * 100%);
  position: relative;
}

[class*=aspect-ratio]  {
  background-color: red;
  > * {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}
