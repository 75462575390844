.leaflet {
  &-control {
    display: none !important;
  }

  &-popup {
    margin-bottom: 40px !important;

    &-content {
      margin: 0 !important;
      width: auto !important;

      &-wrapper {
        padding: 0 !important;
        border-radius: 2px !important;
      }
    }

    &-close-button {
      color: white !important;
      font-weight: normal !important;
    }
  }

  &-marker {
    &-icon {
      outline: none;
    }
  }
}

.hooper {
  &-navigation {
    display: flex;
    position: relative;
    justify-content: center;
  }

  &-prev, &-next {
    position: relative !important;
    top: 0 !important;
    transform: initial !important;
  }
}

.lazy {
  &__loaded {
    animation: lazy-animation .5s linear;
    transition: opacity 1s;
  }
}
img[lazy=loaded] {
  animation: lazy-animation .5s linear;
}

img[lazy=loading] {
    opacity: 0;
}

.vueperslides {
  $root: &;

  &--full-height {
    height: 100%;

    #{$root} {
      &__inner {
        height: 100%;
      }

      &__parallax-wrapper {
        height: 100%;
      }
    }
  }

  &__arrows {
    display: none;
  }

  &__bullets {
    bottom: 24px !important;
    @include breakpoint-respond-down(desktop) {
      display: none !important;
    }
  }
}

.slick {
  &-disabled {
    display: none;
  }
}

// VUEJS MODAL OVERWRITE
.vm {
  &--container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--overlay {
    background-color: rgba(0, 0, 0, .8) !important;
  }

  &--modal {
    top: 0 !important;
    left: 0 !important;
    overflow: scroll !important;
    padding: {
      top: calculate-rem(48);
      right: calculate-rem(24);
      left: calculate-rem(24);
      bottom: calculate-rem(48);
    }
    width: 90% !important;
    @include breakpoint-respond-up(tablet) {
      padding: calculate-rem(48);
    }

    &-tour {
      padding: 0 !important;
      @include breakpoint-respond-up(desktop) {
        width: 75% !important;
      }
      @include breakpoint-respond-up(large) {
        width: 60% !important;
      }

      &-visible {
        overflow: visible !important;
      }
    }
  }

  .v--modal {
    &-overlay {
      background-color: rgba(0, 0, 0, 0.8) !important;
    }

    &-box {
      overflow: visible !important;
    }
  }

  // VideoJS
  .vjs-big-play-centered {
    .vjs-big-play-button {
      margin-left: -1.56666em !important;
      margin-top: -1.56666em !important;
    }
  }

  .video-js {
    * {
      outline: none;
    }

    .vjs-big-play-button {
      background-color: #fff !important;
      border-color: transparent !important;
      border-radius: 100% !important;
      height: 64px !important;
      width: 64px !important;
      top: 33% !important;
      left: 39% !important;
      @include breakpoint-respond-up(tablet) {
        bottom: 32px !important;
        left: 32px !important;
        top: auto !important;
      }

      &:focus, &:hover {
        background-color: rgba(#fff, .75) !important;
      }

      .vjs-icon-placeholder {
        &:before {
          @include fs(42);
          color: map_get($greys, 'grey-900');
          position: static !important;
        }
      }
    }
  }
}
